export default {
    /**
     * 验证手机号
     */
    cellPhone: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[0-9]|7[0135678]|9[89])\d{8}$/,
    /**
     * 验证座机号
     */
    fixedPhone: /\d/
};
